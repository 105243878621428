
























import Vue from 'vue'
import { Getter, Mutation } from 'vuex-class'
import { Component } from 'vue-property-decorator'
import { formattersMap } from '@/lib/Utils'
import { DEPOSIT_WITHIN_HOURS } from '@/data/TransferConstants'
import { GetVirtualAccount } from '@/gateway/remittance/GetVirtualAccount'
import { VirtualAccount } from '@/gateway/remittance/model/VirtualAccount'

@Component
export default class Registered extends Vue {
  @Getter getCreatedRemittance: any
  @Mutation setCreatedRemittance: any

  currentDate: string = ''
  amount: number = 0
  dueDate: string = ''
  virtualAccount: VirtualAccount = {
    bank_code: '',
    account_number: '',
    account_holder_name: ''
  }

  async getVirtualAccount (): Promise<void> {
    const remittanceInfo = this.getCreatedRemittance
    const param = { corp_id: remittanceInfo.corp_id }
    const virtualAccount = await GetVirtualAccount.getInstance().request(param)
    if (!virtualAccount?.account_number) return
    this.virtualAccount = Object.assign({}, virtualAccount)
  }

  setInitialInfo (): void {
    const dayjs = require('dayjs')
    const date = new Date()
    this.currentDate = dayjs(date).format('YYYY-MM-DD HH:mm')
    this.dueDate = dayjs(date).add(DEPOSIT_WITHIN_HOURS, 'hour').format('YYYY-MM-DD HH:mm')
    const remittanceInfo = Object.assign({}, this.getCreatedRemittance)
    this.amount = formattersMap.number(remittanceInfo.amount)
  }

  async created () {
    if (!Object.keys(this.getCreatedRemittance).length) {
      this.$message({
        message: `${this.$t('notification.wrong_approach')}`,
        type: 'warning'
      })
      return this.$router.replace('/history')
    }
    this.setInitialInfo()
    await this.getVirtualAccount()
    this.setCreatedRemittance({})
  }
}
